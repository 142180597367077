
import Banner from "../../components/banner";

import './index.css'
import MedicalDocumentation from "../../components/Dashboard/MedicalDocumentation";

export default function ClinicalNotes() {
  return (
    <div className="case-report">
      <Banner name="xfsdf" backGround="sdfsdf" />
      <div className="container">
       <MedicalDocumentation />
      </div>
    </div>
  )
}
