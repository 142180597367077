

import React, { useState } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import axios from 'axios';
import DischargeSummaryOutput from '../../DischargeSummary/dischargeSummaryOutputPopup';

const { TextArea } = Input

export default function DischargeSummaryForm(props) {
  const [fetching,setFetching]=useState(false);
  const [open,setOpen]=useState(false);
  const [output,setOutput]=useState({})
  
  const [form] = Form.useForm()

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      setFetching(true);
      console.log(values);
      try {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL+"/generate_discharge_summary/", 
          {
            patient_name: values?.patientName,
            patient_email: values?.patientEmail,
            admission_notes: values?.admissionNotes,
            discharge_recommendation: values?.dischargeRecommendation,
          }, 
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        console.log("Form values:", response?.data);
        setOutput(response?.data);
        setFetching(false);
        
       
        // props.setOpenDischargeSummaryForm(false); // Close the form modal
        setOpen(true);     // Open the output modal with a small delay for smooth transition
      } catch (error) {
        if (error?.response && error?.response?.status === 401) {
          message.error("You are not authorized! please login");
        } else {
          message.error("An error occurred while submitting the form.");
        }
        setFetching(false);
      }
    }).catch((errorInfo) => {
      console.error("Validation Failed:", errorInfo);
      setFetching(false);
    });
  };
  
  

  const handleCancel = () => {
    props.setOpenDischargeSummaryForm(false);
  }

  return (
    <div className="flex items-center justify-center  bg-gray-100">
      <Modal
        title="New Discharge Summary"
        visible={props.openDischargeSummaryForm}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      
        maskClosable={false}
        className='w-full'
      >
        <Form form={form} layout="vertical" className="mt-4"  requiredMark={false}>
          <Form.Item
            name="patientName"
            label="Name of the Patient"
            rules={[{ required: true, message: 'Please input the patient name!' }]}
          >
            <Input placeholder="Eg James Franklin" />
          </Form.Item>
          <Form.Item
            name="patientEmail"
            label="Email of the Patient"
            rules={[
              { required: true, message: 'Please input the patient email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input placeholder="Eg : abc@gmail.com" />
          </Form.Item>
          <Form.Item
            name="admissionNotes"
            label="Admission Notes"
            rules={[{ required: true, message: 'Please input admission notes!' }]}
          >
            <TextArea rows={4} placeholder="Enter admission notes" />
          </Form.Item>
          <Form.Item
            name="dischargeRecommendation"
            label="Discharge Recommendation"
            rules={[{ required: true, message: 'Please input discharge recommendation!' }]}
          >
            <TextArea rows={4} placeholder="Enter discharge recommendation here" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={fetching}
              className="w-[35%] mx-[30%] primary text-white text-sm text-center"
              onClick={handleOk}
            >
              Generate Summary
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <DischargeSummaryOutput data={output} isOpen={open} onClose={setOpen} />
    </div>
  )
}