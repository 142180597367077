
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { AppRoutesConst } from './app-const';
import {jwtDecode} from 'jwt-decode';
import React, { Suspense,createContext,useEffect,useState } from 'react';
import MainLayout from "./layouts/MainLayout";
import ClincialNote from './pages/clinicalNoteGeneration';
import ClinicalNotes from './pages/clinicalNote';
// import WebViewRedirect from './components/WebViewRedirect';




interface MyData {
  Title: string;
  Key_Words:string;
  Abstract:string;
  Introduction:string;
  Case_Presentation:string;
  Discussion:string;
  Conclusion:string;
  References:string;
}

interface summaryData {
Summary:string
}



interface DiagnosisData {
  DISEASE: { [key: string]: string };  // Allow any key
  Type: { [key: string]: string };     // Allow any key
  CATEGORY: { [key: string]: string }; // Allow any key
  DIAGNOSIS: { [key: string]: string }; // Allow any key
  TREATMENT: { [key: string]: string }; // Allow any key
  EVALUATION: { [key: string]: string }; // Allow any key
  References: { [key: string]: string }; // Allow any key
}


interface DrugInformation {
  index: { [key: string]: string };
  Drug_generic_name: { [key: string]: string };
  Drug_trade_name: { [key: string]: string };
  Uses: { [key: string]: string };
  Dosage_Adult_and_Children: { [key: string]: string };
  Relevant_Information: { [key: string]: string };
}
export const MedboxContext = createContext({
  
  value: '',
  setValue: (data:string)=>{},
  fetchData: null as MyData | null,
  setFetchData: (data: MyData | null) => {},
  fetchSummaryData: null as summaryData | null,
  setFetchSummaryData: (data: summaryData | null) => {},
  fetchDiseaseData: null as DiagnosisData |null,
  setFetchDiseaseData:(data: DiagnosisData | null) => {},
  fetchDrugDosageData: null as DrugInformation | null,
  setFetchDrugDosageData: (data:DrugInformation|null)=>{},
  // validateInput: (input: string) => {},
});


// public pages
const HomePage = React.lazy(() => import("./pages/home"));
const CaseReportPage = React.lazy(() => import("./pages/caseReport"));
const SummaryPage = React.lazy(() => import("./pages/summary"));
const SearchTreatmentPage = React.lazy(() => import("./pages/searchTreatment"));
const DrugDosagePage = React.lazy(() => import("./pages/drugDosage"));
const PubMedcentralPage = React.lazy(() => import("./pages/pubMedCentral"));
const MembershipPage = React.lazy(() => import("./pages/membership"));
const ContributorsPage = React.lazy(() => import("./pages/contributors"));
const AimedboxBotPage = React.lazy(() => import("./pages/aimedboxBot"));
const ContactUsPage = React.lazy(() => import("./pages/contactUs"));
const TermsCondition = React.lazy(() => import("./pages/termsCondition"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacyPolicy"));



interface MetaTag {
  [key: string]: any;
  name?: string;
  property?: string;
  content: string;
}


function App() {
  const [fetchData, setFetchData] = useState<any>(null);
  const [fetchSummaryData, setFetchSummaryData] = useState<any>(null);
  const [fetchDiseaseData, setFetchDiseaseData] = useState<any>(null);
  const [fetchDrugDosageData,setFetchDrugDosageData]= useState<any>(null);
  const [value,setValue]=useState("1");



  // useEffect(() => {
  //   const metaTags: MetaTag[] = [
  //     // Instagram specific
  //     {
  //       property: 'al:ios:url',
  //       content: 'https://aimedbox.com'
  //     },
  //     {
  //       property: 'al:ios:app_store_id',
  //       content: '535886823' // Chrome App Store ID
  //     },
  //     {
  //       property: 'al:ios:app_name',
  //       content: 'Chrome'
  //     },
  //     {
  //       property: 'al:android:url',
  //       content: 'https://aimedbox.com'
  //     },
  //     {
  //       property: 'al:android:package',
  //       content: 'com.android.chrome'
  //     },
  //     {
  //       property: 'al:android:app_name',
  //       content: 'Chrome'
  //     },
  //     // General
  //     {
  //       name: 'apple-mobile-web-app-capable',
  //       content: 'yes'
  //     },
  //     {
  //       name: 'mobile-web-app-capable',
  //       content: 'yes'
  //     },
  //     {
  //       name: 'apple-mobile-web-app-status-bar-style',
  //       content: 'black'
  //     },
  //     {
  //       property: 'og:url',
  //       content: 'https://aimedbox.com'
  //     },
  //     {
  //       name: 'format-detection',
  //       content: 'telephone=no'
  //     },
  //     {
  //       name: 'browsermode',
  //       content: 'application'
  //     }
  //   ];

  //   // Add meta tags
  //   metaTags.forEach((tag: MetaTag) => {
  //     const meta = document.createElement('meta');
  //     Object.entries(tag).forEach(([key, value]: [string, any]) => {
  //       meta.setAttribute(key, value);
  //     });
  //     document.head.appendChild(meta);
  //   });

  //   // Cleanup
  //   return () => {
  //     metaTags.forEach((tag: MetaTag) => {
  //       const firstKey = Object.keys(tag)[0] as string;
  //       const selector = `meta[${firstKey}="${tag[firstKey]}"]`;
  //       const meta = document.querySelector(selector) as HTMLMetaElement | null;
  //       if (meta) {
  //         meta.remove();
  //       }
  //     });
  //   };
  // }, []);



 

  useEffect(() => {
    const yourToken = localStorage.getItem("accessToken")
    if (!yourToken) {
    
      return;
    }

    try {
      const decodedToken = jwtDecode(yourToken);
      if (decodedToken && decodedToken.exp) {
        const expiryTime = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000); 
      

        if (currentTime > expiryTime) {
          console.log('Token has expired');
          localStorage.removeItem('accessToken'); 
          window.location.href = window.location.origin;
          return;
        
        }
      } else {
        console.log('Invalid token format or missing expiry claim');
      }
    } catch (error) {
      console.error('Error decoding or processing token:', error);
    }
   
  }, []);

  
  
  return (
    <MedboxContext.Provider value={{  value,setValue,fetchSummaryData,setFetchSummaryData,fetchData,setFetchData, fetchDiseaseData,setFetchDiseaseData ,fetchDrugDosageData,setFetchDrugDosageData,
    // validateInput
    }}>
    <BrowserRouter>
    {/* <WebViewRedirect /> */}
      <Routes>
      <Route element={<MainLayout />}>
        <Route index element={<Suspense><HomePage /></Suspense>} />
        <Route path={AppRoutesConst.caseReport} element={<Suspense><CaseReportPage /></Suspense>} />
        <Route path={AppRoutesConst.dischargeSummary} element={<Suspense><SummaryPage/></Suspense>} />
        <Route path={AppRoutesConst.clinicalNote} element={<Suspense><ClinicalNotes/></Suspense>} />
        <Route path={AppRoutesConst.searchTreatment} element={<Suspense><SearchTreatmentPage /></Suspense>} />
        <Route path={AppRoutesConst.drugDosage} element={<Suspense><DrugDosagePage /></Suspense>} />
        <Route path={AppRoutesConst.resources} element={<Suspense><PubMedcentralPage /></Suspense>} />
        <Route path={AppRoutesConst.membership} element={<Suspense><MembershipPage /></Suspense>} />
        <Route path={AppRoutesConst.contributors} element={<Suspense><ContributorsPage /></Suspense>} />
        <Route path={AppRoutesConst.contactUs} element={<Suspense><ContactUsPage /></Suspense>} />
        <Route path={AppRoutesConst.privacyPolicy} element={<Suspense><PrivacyPolicy /></Suspense>} />
        <Route path={AppRoutesConst.termsCondition} element={<Suspense><TermsCondition /></Suspense>} />
        <Route path={AppRoutesConst.medboxbot} element={<Suspense><AimedboxBotPage/></Suspense>} /> 
        <Route path={AppRoutesConst.generateClinicalNote} element={<Suspense><ClincialNote/></Suspense>} /> 

      </Route>
      </Routes>
    </BrowserRouter>
    </MedboxContext.Provider>
  );
}

export default App;
