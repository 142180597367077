

import React, { useEffect, useRef, useState } from "react";
import { Button, Select, Collapse, Checkbox, Radio, Input, Spin, message, Modal } from "antd";
import {
  LeftOutlined,
  AudioOutlined,
  EditOutlined,
  CopyOutlined,
  AudioMutedOutlined,
  FilePdfOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClinicalNotePatientForm from "../Dashboard/forms/ClinicalNotePatientForm";
import DifferentialDiagnosisPopup from "../DifferentialDiagnosis/Popup";
import ClinicalNotesModal from "./ClinicalNoteOutput/clinicalNotePopup";
import ClinicalNoteTemplate from "./clinicalNoteTemplate";
import useScreenType from "react-screentype-hook";

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;



export default function  ClinicalNotes() {
    const navigate= useNavigate();

   
    const [data, setData] = useState([])
    const [isEditable, setIsEditable] = useState(false);
    const [clinicalOutput,setClinicalOutput]=useState({})
    const [openOutPut,setOpenOutPut]=useState(false);
    const [templateModal,setTemplateModal]=useState(false);

    

    useEffect(() => {
     
        const storedData = sessionStorage.getItem('clinical-data')
        
        if (storedData) {
          
          try {
            const parsedData = JSON.parse(storedData)
            setData(parsedData)
          } catch (error) {
            console.error('Error:', error)
          }
        }
      }, [])
  

    const handleEdit = () => {
        setIsEditable(true);
      };
    
    const handleCopy = async () => {
        try {
            
          await navigator.clipboard.writeText(liveTranscription);
          message.success("Transcription copied to clipboard!");
        } catch (err) {
          message.error("Failed to copy transcription.");
        }
      };

  
 
  const [selectedValue, setSelectedValue] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [chiefFetching, setChiefFetching] = useState(false);
  const [diagnosisFetching, setDiagnosisFetching] = useState(false);
  const [differentialDiagnosisPopup,setDifferentialDiagnosisPopup]=useState(false);
  const [differentialDiagnosisPopupData,setDifferentialDiagnosisPopupData]=useState([]);

  const [patientDataFethcing, setPatientDataFetching] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState("");
  const canvasRef = useRef(null);

 
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);

  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const isInitialRender = useRef(true);
  const [noteType, setNoteType] = useState("Soap");
  const [language, setLanguage] = useState("en");

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const screenType = useScreenType();


  const [chiefComplaintData, setChiefComplaintData] = useState(null);
  const [selectedComplaints, setSelectedComplaints] = useState({
    "Pain best described as": [],
    "Problem is": [],
    "Triggered or worsened by": [],
    "Relieved by": [],
    "Associated with": [],
  });
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState([]);

  const [ws, setWs] = useState(null);
  const [liveTranscription, setLiveTranscription] = useState("");
  const [open,setOpen]=useState(false);

  const handleEditPatient = ()=>{
    setOpen(true);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (transcription?.transcript && !chiefComplaintData) {
        fetchChiefComplaints();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [transcription?.transcript]);

  // Add debounced effect for differential diagnosis
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const timer = setTimeout(() => {
      const hasSelectedComplaints = Object.values(selectedComplaints).some(
        (arr) => arr.length > 0
      );
      if (hasSelectedComplaints) {
        handleSubmitComplaints();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [selectedComplaints]);

  const DiagnosisPopup= (diagnosis)=> {
    

   
    const diagnosisMatch = differentialDiagnosis.filter(
          (item) => item.diagnosis === diagnosis
        );
    console.log(diagnosisMatch)
    setDifferentialDiagnosisPopupData(diagnosisMatch)
    setDifferentialDiagnosisPopup(true)
}



  const startRecordAndConsent =()=>{
    setShowConsentModal(true);
  }


  const startRecording = async () => {
    try {
      const token =localStorage.getItem("accessToken")
      if (!token) {
        message.error("You are not authorized! please login");
        return;
      }

      const websocket = new WebSocket("wss://api.aimedbox.com/ws/transcribe/");
      let chunkCounter = 0;
      const MAX_CHUNKS = 10;
      let mediaRecorder = null;

      websocket.onopen = async () => {
        console.log("WebSocket Connected");

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Initialize a new MediaRecorder for each chunk
        const startNewRecording = () => {
          if (chunkCounter >= MAX_CHUNKS) return;

          mediaRecorder = new MediaRecorder(stream, {
            mimeType: "audio/webm;codecs=opus",
          });

          let chunks = [];

          mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
          };

          mediaRecorder.onstop = async () => {
            if (chunks.length > 0) {
              const blob = new Blob(chunks, { type: "audio/webm;codecs=opus" });

              if (websocket.readyState === WebSocket.OPEN) {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64Audio = reader.result.split(",")[1];
                  const message = {
                    audio: base64Audio,
                    language: language,
                  };
                  websocket.send(JSON.stringify(message));
                };
                reader.readAsDataURL(blob);
              }

              console.log(`Processed chunk ${chunkCounter}`);
              chunks = [];
              chunkCounter++;

              if (chunkCounter < MAX_CHUNKS) {
                startNewRecording();
              }
            }
          };

          mediaRecorder.start();

          setTimeout(() => {
            if (mediaRecorder && mediaRecorder.state === "recording") {
              mediaRecorder.stop();
            }
          }, 5000);
        };

        audioContextRef.current = new AudioContext();
        analyserRef.current = audioContextRef.current.createAnalyser();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);
        analyserRef.current.fftSize = 2048;

        startNewRecording();
        setIsRecording(true);
        drawWaveform();

        setLiveTranscription("");
        setTranscription({ transcript: "" });
      };

      websocket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log("Received transcription:", data);
          if (data.transcript) {
            setLiveTranscription((prev) => prev + " " + data.transcript);
            setTranscription((prevTranscription) => ({
              transcript: prevTranscription.transcript + " " + data.transcript,
            }));
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      websocket.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      websocket.onclose = () => {
       message.error("WebSocket connection could not be  established");
      };

      setWs(websocket);
    } catch (err) {
      console.error("Error in startRecording:", err);
    }
  };

  const handleConsentSubmit = () => {
    if (!consentChecked) {
      message.error("Please check the consent box to proceed.");
      return;
    }
    startRecording();
    setShowConsentModal(false); 
    
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }

    if (ws) {
      ws.close();
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
    }

    setIsRecording(false);
    cancelAnimationFrame(animationFrameRef.current);
  };



  const fetchChiefComplaints = async () => {
    setChiefFetching(true);
    if (transcription?.transcript) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL+"/chief_complaint/",
          {
            audio_transcript: transcription?.transcript,
            form_type: data[0]?.form_type,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
            },
          }
          

        );
        setChiefComplaintData(response.data);
        const initialSelections = {};

        Object.keys(response.data.symptoms).forEach((key) => {
          initialSelections[key] = [];
        });
        Object.keys(response.data.characterized_by).forEach((key) => {
          initialSelections[key] = [];
        });
        Object.keys(response.data.triggered_or_worsened_by).forEach((key) => {
          initialSelections[key] = [];
        });
        Object.keys(response.data.relieved_by).forEach((key) => {
          initialSelections[key] = [];
        });
        Object.keys(response.data.associated_with).forEach((key) => {
          initialSelections[key] = [];
        });
        setSelectedComplaints(initialSelections);
        setChiefFetching(false);
      } catch (error) {
        if (error?.response?.status == 401) {
          message.error("You are not authorized! please login");
        }
        console.error("Error fetching chief complaints:", error);
        setChiefFetching(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  const handleViewPdf = () => {
    const pdfUrl = process.env.REACT_APP_BASE_URL + data[0]?.file_url;
    window.open(pdfUrl, "_blank");
  };

  const handleComplaintChange = (category, values) => {
    setSelectedComplaints((prev) => ({
      ...prev,
      [category]: values,
    }));
  };

  const handleSubmitComplaints = async () => {
    setDiagnosisFetching(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL+"/differential_diagnosis/",
        {
          form_type: data[0]?.form_type,
          chief_complaint: chiefComplaintData?.chief_complaint,
          additional_symptoms: selectedComplaints,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
          },
        }

      );
      setDifferentialDiagnosis(response.data);
      setSelectedValue(response.data.map((data) => data.diagnosis));
      setDiagnosisFetching(false);
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
      }
      console.error("Error fetching differential diagnosis:", error);
      setDiagnosisFetching(false);
    }
  };


  const handleTemplateEdit = ()=>{
    if(selectedValue.length ==0){
      message.error("Please generate chief complaints and diagnosis to generate clinical note")
      return
    }
    setTemplateModal(true)
  }

  const handleSave = (formData) => {
  
    console.log('Saving data:', formData);
    generateClinicalNotes(formData)
    setTemplateModal(false);
  };
  const generateClinicalNotes = async (template_json) => {
   
   
    setFetching(true);
    console.log(selectedComplaints)
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL+"/generate_clinical_note/",
        {
          audio_transcript: transcription.transcript,
          patient_name: data[0]?.patient_name,
          chief_complaint: chiefComplaintData?.chief_complaint,
          additional_symptoms: selectedComplaints,
          form_json: data[0]?.form_json ?data[0]?.form_json :{},
          template_json:template_json,
          differential_diagnosis: selectedValue,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
          },
        }
      );
      setFetching(false);
      setClinicalOutput(response?.data)
      setOpenOutPut(true)
   
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
      }
      setFetching(false);
      console.error("Error fetching note:", error);
    }
  };

  const drawWaveform = () => {
    if (!canvasRef.current || !analyserRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) return;

    const width = canvas.width;
    const height = canvas.height;
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    canvasCtx.clearRect(0, 0, width, height);

    const draw = () => {
      animationFrameRef.current = requestAnimationFrame(draw);
      analyserRef.current.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = "rgb(240, 240, 240)";
      canvasCtx.fillRect(0, 0, width, height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "rgb(0, 122, 255)";
      canvasCtx.beginPath();

      const sliceWidth = width / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(width, height / 2);
      canvasCtx.stroke();
    };

    draw();
  };

 

  return (
    <div className="min-h-screen p-4">
      <div className="w-full  shadow-lg rounded-lg overflow-hidden">
        <div className="p-4 border-b flex items-center justify-start">
          <Button onClick={(e)=>navigate(-1)} icon={<LeftOutlined />} type="text" />
          <h1 className="text-xl font-semibold ml-1 mt-1">Clinical Notes</h1>
        </div>
        {patientDataFethcing ? (
          <div className="flex justify-center mt-3">
            <Spin indicator={<LoadingOutlined />} size="small" />
          </div>
        ) : (
          <div className="w-full space-y-4 p-4">
            <div className="p-4 rounded-lg shadow-sm">
              <div className="flex lg:justify-between max-md:flex-col items-center">
                <div className="grid grid-cols-3 max-md:grid-cols-2 gap-4 flex-1">
                  <div>
                    <p className="text-sm text-black font-medium mb-1">Patient Name</p>
                    <p className="text-base text-gray-900">
                      {data[0]?.patient_name}
                    </p>
                  </div>
                 
                  <div>
                    <p className="text-sm text-black mb-1 font-medium">
                      Patient Date of Birth
                    </p>
                    <p className="text-base text-gray-900">
                      {data[0]?.patient_dob}
                    </p>
                  </div>
                </div>
                <Button
                  type="primary"
                  ghost
                  className="mt-2"
                  onClick={handleEditPatient}
                >
                  Edit Patient Information
                </Button>
              </div>
            </div>

            {data[0]?.form_name && <div className="bg-gray-50 p-4 rounded-lg">
             <div className="flex justify-between items-center">
                <div>
                  <p className="text-blue-500 hover:text-blue-600 cursor-pointer text-base">
                    {data[0]?.form_name}
                  </p>
                 
                </div>
                <Button
                  type="link"
                  icon={<FilePdfOutlined />}
                  onClick={handleViewPdf}
                  className="text-blue-500 hover:text-blue-600"
                >
                  View Form
                </Button>
              </div>
            </div>}
          </div>
        )}

        <div className="flex w-full max-md:flex-col">
          <div className="w-2/3 max-md:w-full p-4 lg:border-r">
            <div className="mb-4">
              <div className="h-12 bg-gray-200 bg-opacity-70 border rounded-lg flex items-center justify-between px-4">
                {isRecording ? (
                  <AudioOutlined className="text-blue-500" />
                ) : (
                  <AudioMutedOutlined className="text-blue-500" />
                )}
                <canvas ref={canvasRef} className="w-full h-full px-2" />
                <Button
                  type="primary"
                  onClick={isRecording ? stopRecording : startRecordAndConsent}
                  className={
                    isRecording ? "bg-red-500 text-white" : "primary text-white"
                  }
                >
                  {isRecording ? "Stop Recording" : "Start Recording"}
                </Button>
              </div>
            </div>

            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2">
                Transcription
              </h2>
              <TextArea
                rows={10}
                value={liveTranscription}
                onChange={(e) => {
                  setLiveTranscription(e.target.value);
                  setTranscription({ transcript: e.target.value });
                }}
                placeholder="Transcription will appear here..."
                disabled={!isEditable}
              />
            </div>

            <div className="flex justify-start gap-x-2 mb-4">
              <Button  onClick={handleEdit} icon={<EditOutlined />} />
              <Button onClick={handleCopy} icon={<CopyOutlined />} />
            </div>

            {!screenType.isMobile  && <Button
              onClick={handleTemplateEdit}
              disabled={
                !chiefComplaintData && !differentialDiagnosis && !transcription
              }
              loading={fetching}
              type="primary"
              className="primary text-white"
            >
              Generate Clinical Notes
            </Button>}
          </div>

          <div className="w-1/3 max-md:w-full p-4">
            <div className="!flex !justify-start !items-center !gap-3 !mb-4 w-full">
              <Select
                className="w-1/2"
                placeholder="Select Note Type"
                onChange={(value) => setNoteType(value)}
                value={noteType}
              >
                <Option value="Soap">Soap</Option>
                <Option value="Progress">Progress</Option>
              </Select>

              <Select
                className="w-1/2"
                placeholder="Select Language"
                value={language}
                onChange={(value) => setLanguage(value)}
              >
                <Option value="en">English</Option>
                <Option value="es">Spanish</Option>
              </Select>
            </div>

            <Collapse>
              <Panel
                header={
                  <div className="flex items-center justify-between">
                    <span>Chief Complaint</span>
                    {chiefFetching && <Spin size="small" />}
                  </div>
                }
                key="1"
              >
                {chiefComplaintData && (
                  <div className="space-y-4 mt-1">
                    <h3 className="font-semibold text-lg mb-2">
                      Chief Complaint: {chiefComplaintData.chief_complaint}
                    </h3>

                    {Object.entries(chiefComplaintData.symptoms).map(
                      ([category, options]) => (
                        <div key={category} className="mb-4">
                          <p className="font-medium">{category}:</p>
                          <Checkbox.Group
                            options={options}
                            value={selectedComplaints[category]}
                            onChange={(values) =>
                              handleComplaintChange(category, values)
                            }
                          />
                        </div>
                      )
                    )}

                    {Object.entries(chiefComplaintData.characterized_by).map(
                      ([category, options]) => (
                        <div key={category} className="mb-4">
                          <p className="font-medium">{category}:</p>
                          <Checkbox.Group
                            options={options}
                            value={selectedComplaints[category]}
                            onChange={(values) =>
                              handleComplaintChange(category, values)
                            }
                          />
                        </div>
                      )
                    )}

                    {Object.entries(
                      chiefComplaintData.triggered_or_worsened_by
                    ).map(([category, options]) => (
                      <div key={category} className="mb-4">
                        <p className="font-medium">{category}:</p>
                        <Checkbox.Group
                          options={options}
                          value={selectedComplaints[category]}
                          onChange={(values) =>
                            handleComplaintChange(category, values)
                          }
                        />
                      </div>
                    ))}

                    {Object.entries(chiefComplaintData.relieved_by).map(
                      ([category, options]) => (
                        <div key={category} className="mb-4">
                          <p className="font-medium">{category}:</p>
                          <Checkbox.Group
                            options={options}
                            value={selectedComplaints[category]}
                            onChange={(values) =>
                              handleComplaintChange(category, values)
                            }
                          />
                        </div>
                      )
                    )}

                    {Object.entries(chiefComplaintData.associated_with).map(
                      ([category, options]) => (
                        <div key={category} className="mb-4">
                          <p className="font-medium">{category}:</p>
                          <Checkbox.Group
                            options={options}
                            value={selectedComplaints[category]}
                            onChange={(values) =>
                              handleComplaintChange(category, values)
                            }
                          />
                        </div>
                      )
                    )}
                  </div>
                )}
              </Panel>

              <Panel
                header={
                  <div className="flex items-center justify-between">
                    <span>Differential Diagnosis</span>
                    {diagnosisFetching && <Spin size="small" />}
                  </div>
                }
                key="2"
              >
                <div className="mt-4 bg-gray-100 mx-2 h-[300px] pt-4 pb-4 overflow-y-auto">
                  {selectedValue.map((value, i) => (
                    <div key={i} className="px-4 mt-2 flex justify-between">
                      <p className="text-black font-base underline underline-offset-4">
                        {value}
                      </p>
                      <p
                        onClick={()=>DiagnosisPopup(value)}
                        className="text-blue-400 font-base underline underline-offset-4 cursor-pointer"
                      >
                        See factors
                      </p>
                    </div>
                  ))}
                </div>
              </Panel>
            </Collapse>
            <div className="mt-2">
              <img src="/banner-emr.png" className="px-2 z-20"alt="banner" />
            </div>
            {screenType.isMobile && 
            <div className="w-full mt-4 flex justify-center items-center">
            <Button
              onClick={handleTemplateEdit}
              disabled={
                !chiefComplaintData && !differentialDiagnosis && !transcription
              }
              loading={fetching}
              type="primary"
              className="primary text-white"
            >
              Generate Clinical Notes
            </Button></div>}
          </div>
          <ClinicalNotePatientForm isOpen={open} setOpenPatientClinicalForm={setOpen} edit={data[0]} />
          <DifferentialDiagnosisPopup isOpen={differentialDiagnosisPopup} onClose={setDifferentialDiagnosisPopup} tabContent={differentialDiagnosisPopupData[0]} />
          {clinicalOutput &&<ClinicalNotesModal data={clinicalOutput} isOpen={openOutPut} onClose={setOpenOutPut} /> }
          
      <Modal
        title="Consent for Audio Recording"
        visible={showConsentModal}
        maskClosable={false}
        onCancel={() => setShowConsentModal(false)}
        footer={[
          <Button key="submit" type="primary" className="primary py-1 px-4 h-10" onClick={handleConsentSubmit}>
            Submit
          </Button>,
        ]}
      >
        <p>
          To ensure comprehensive and accurate medical documentation, this
          session will be audio recorded. The recording will be used solely to
          generate clinical notes and will be securely stored, accessible only
          by authorized personnel. Your participation is voluntary, and you may
          withdraw your consent at any time without impacting your treatment. By
          proceeding, you consent to this session being recorded for the stated
          purpose.
        </p>
        <Checkbox
          onChange={(e) => setConsentChecked(e.target.checked)}
          checked={consentChecked}
        >
          I allow to record this session
        </Checkbox>
      </Modal>
      <ClinicalNoteTemplate
  isVisible={templateModal}
  onClose={() => setTemplateModal(false)}
  onSave={handleSave}
/>
        </div>
      </div>
    </div>
  );
}
