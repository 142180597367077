'use client'

import React, { useEffect, useState } from 'react'
import { Modal, Button, message, Input } from 'antd'
import { PrinterOutlined, EditOutlined, CopyOutlined, CloseOutlined, SaveOutlined, DownOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons'
import axios from 'axios'

export default function DischargeSummaryOutput({isOpen,onClose, data,view,id}) {

  const [isEditing, setIsEditing] = useState(false)
  const [editedSummary, setEditedSummary] = useState(data?.discharge_summary || '')
  useEffect(() => {
    console.log(data)
    if (data?.discharge_summary) {
      setEditedSummary(data?.discharge_summary)
    }
  
  }, [data])

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = async () => {
    console.log(editedSummary)
    try {
      await axios.post(process.env.REACT_APP_BASE_URL+'/save_discharge_summary/', { 
        id:id?id:data?.id,
        discharge_summary: editedSummary
       },
       {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
        },
      }
      )
      message.success('Summary saved successfully')
      setIsEditing(false)
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return
      }
      message.error('Failed to save summary')
    }
  }
  

 

  const handleRegenerateSummary = () => {
    onClose(!isOpen)

    
   
  }

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/download_summary/${id || data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob", // Ensure the response is treated as a file
        }
      );
  
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
      const url = window.URL.createObjectURL(blob);
  
      // Trigger file download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${data?.patient_name}_discharge_summary.docx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      message.error("Error downloading the file. Please try again.");
    }
  };
  
  const handlePrint = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/download_summary/${id || data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob", // Fetch the file as a blob
        }
      );
  
      // Create a URL for the Word file
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);
  
      // Automatically open the file
      const link = document.createElement("a");
      link.href = url;
      link.download = `${data?.patient_name || "summary"}.docx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      // Notify the user to print the file
      message.info("The Word file has been downloaded. Please open it to print.");
    } catch (error) {
      console.error("Error downloading the Word file:", error);
      message.error("Error downloading the file for printing. Please try again.");
    }
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Modal
       visible={isOpen}
        onCancel={()=>onClose(!isOpen)}
        footer={null}
       
        closable={false}
        maskClosable={false}
        bodyStyle={{ padding: 0 }}
        className="rounded-none w-full"
      >
        <div className="bg-[#0096D6] text-white p-4 flex justify-between items-center">
          <span className="text-lg font-semibold">Discharge Summary - Output</span>
          <button onClick={(e)=>onClose(!isOpen)} className="text-white hover:text-gray-200">
            <CloseOutlined className="text-xl" />
          </button>
        </div>
        <div className="p-2 overflow-y-auto h-max-[500px]">
          <div className="bg-[#F0F0F0] p-4 mb-4 flex justify-between text-sm">
            <span>Patient Name : {data?.patient_name}</span>
            <span>Date : {data?.created_at}</span>
          </div>
          <div className="space-y-4 text-sm">
            {isEditing ? (
              <Input.TextArea
                value={editedSummary}
                onChange={(e) => setEditedSummary(e.target.value)}
                rows={12}
                className="w-full"
              />
            ) : (
              <Input.TextArea
                value={editedSummary}
                rows={15}
                className="w-full outline-none"
              />
            )}
          </div>
         {!view &&  <div className="flex justify-between items-center mt-6">
            <div className="space-x-2">
              <Button icon={<PrinterOutlined /> }onClick={handlePrint} className="border-none shadow-none" />
             

             <Button icon={<DownloadOutlined />} onClick={handleDownload} className="border-none shadow-none"  />
             {isEditing ?<Button
                  icon={<SaveOutlined />}
                  className="border-none shadow-none"
                  onClick={handleSave}
                />:<Button
                icon={<EditOutlined />}
                className="border-none shadow-none"
                onClick={handleEdit}
              />}
             
            </div>
            <div className="flex gap-3 max-md:flex-col">
            <Button 
                type="primary"
                icon={<SyncOutlined />}
                className="primary text-white"
              >
                EHR/EMR Sync
              </Button>
            <Button 
              type="primary"
              onClick={handleRegenerateSummary} 
              className="primary text-white"
            >
              Regenerate Summary
            </Button>
            </div>
          </div>}
        </div>
      </Modal>
    </div>
  )
}