import React, { useState } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ClinicalNoteTemplate = ({ isVisible, onClose, onSave }) => {
  const [customSectionTitle, setCustomSectionTitle] = useState('');
  const [sections, setSections] = useState({
    subjective: [
        { title: 'Chief Complaint', content: '' },
        { title: 'History of Present Illness (HPI)', content: '' },
        { title: 'Pertinent Past Medical History', content: '' },
        { title: 'Review of Systems (ROS)', content: '' }
      ],
      objective: [
        { title: 'Vital Signs, Labs, Imaging, Microbiology, special tests, echo, stress test', content: '' },
        { title: 'Physical Exam', content: '' }
      ],
      assessment: [
        { title: 'Problem List', content: '' },
        { title: 'Diagnosis', content: '' },
        { title: 'Differential diagnosis', content: '' }
      ],
      plan: [
        { 
          title: 'Create a list of all of the patient\'s medical problems. Your problem list should be ordered by acuity.',
          content: '' 
        },
        {
          title: 'Propose a plan to manage each problem you\'ve identified. Further diagnostics, treatment, referrals, patient education and follow up.',
          content: '' 
        }
      ]
  });

  const deleteSection = (category, index) => {
    setSections(prev => ({
      ...prev,
      [category]: prev[category].filter((_, i) => i !== index)
    }));
  };

  const addSubSection = (category) => {
    if (customSectionTitle.trim() ==""){
        message.error("filled the section name to add")
        return;
    }
    
    setSections(prev => ({
      ...prev,
      [category]: [...prev[category], { title: customSectionTitle, content: '' }]
    }));
    
    setCustomSectionTitle('');
  };

  const handleSave = () => {
    message.success("Notes template has been saved!")
    onSave(sections);
  };

  const renderSections = (category, title) => (
    <div className="mb-6 bg-white rounded-lg p-4">
      <h2 className="text-xl font-semibold mb-4">{title}:</h2>
      <div className="space-y-2">
        {sections[category].map((section, index) => (
          <div key={`${category}-${index}`} className="flex justify-between items-center p-2 border rounded">
            <span className="font-medium">{section.title}</span>
            <Button
              type="text"
              onClick={() => deleteSection(category, index)}
              icon={<DeleteOutlined />
                
              }
            />
          </div>
        ))}
      </div>
      <div className="mt-4 flex space-x-2">
        <Input
          placeholder="Enter new section title"
          value={customSectionTitle}
          onChange={(e) => setCustomSectionTitle(e.target.value)}
          onPressEnter={() => addSubSection(category)}
        />
        <Button type="primary" className='primary px-2' onClick={() => addSubSection(category)}>
          + Add Section
        </Button>
      </div>
    </div>
  );

  return (
    <Modal 
      title="Clinical Notes (SOAP Note)"
      open={isVisible}
      onCancel={onClose}
      className='w-full'
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" className='primary px-2' onClick={handleSave}>
          Save Notes
        </Button>
      ]}
    >
      <div className="max-h-[70vh] overflow-y-auto">
        {renderSections('subjective', 'Subjective')}
        {renderSections('objective', 'Objective')}
        {renderSections('assessment', 'Assessment (A)')}
        {renderSections('plan', 'Plan (P)')}
      </div>
    </Modal>
  );
};

export default ClinicalNoteTemplate;