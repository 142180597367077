import { Modal, Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const DifferentialDiagnosisPopup = ({ isOpen, onClose, activeTab = '1', tabContent }) => {
console.log(tabContent);
  const items = [
    {
      key: '1',
      label: 'Associated Factors',
      children: (
        <div
        className="text-gray-700"
        dangerouslySetInnerHTML={{
          __html: tabContent?.associated_factors?.replace(/\n/g, '<br />')
        }}
      />
      ),
    },
    {
      key: '2',
      label: 'Treatment',
      children: tabContent?.treatment,
    },
    {
      key: '3',
      label: 'Diagnosis',
      children: tabContent?.diagnosis,
    },
    {
      key: '4',
      label: 'Evaluation',
      children: tabContent?.evaluation,
    },
    {
      key: '5',
      label: 'References',
      children: tabContent?.references,
    },
  ];

  return (
    <Modal
      open={isOpen}
      onCancel={()=>onClose(!isOpen)}
      footer={null}
    
      maskClosable={false}
      closeIcon={<CloseOutlined className="text-white" />}
      className="appendicitis-modal w-full"
    >
      <div className="bg-[#1e3a8a] text-white p-4 -mt-4 -mx-6 mb-4">
        <h2 className="text-xl font-semibold mb-2 text-center">Appendicitis</h2>
        {/* <p className="text-sm">Chief Complaint : Abdominal Pain</p> */}
      </div>
      
      <Tabs
        defaultActiveKey={activeTab}
        items={items}
        className="appendicitis-tabs"
      />
    </Modal>
  );
};

export default DifferentialDiagnosisPopup;
