import React from 'react'
import image from './../assets/images/banner.png'

interface Props {
  backGround: string,
  name: string,
}
export default function Banner(props: Props) {
  return (
    <div className='banner' style={{background: `url(${image})`, width: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height:200, backgroundRepeat: 'no-repeat'}}>
    </div>
  )
}
