import {  Row } from "antd";
import Banner from "../../components/banner";
import './index.css'
import ClinicalNotes from "../../components/ClinicalNote/clinicalNote";

export default function ClincialNote() {
  return (
    <div className="case-report">
      <Banner name="xfsdf" backGround="sdfsdf" />
      <div className="container">
       <ClinicalNotes />
      </div>
    </div>
  )
}
