
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Button, Spin, Alert, message } from 'antd';
import { EyeOutlined, EditOutlined, DownloadOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons';
import DischargeSummaryForm from './forms/DischargeSummaryForm';
import PatientIntakeForm from './forms/PatientIntakeForm';
import ClinicalNotePatientForm from './forms/ClinicalNotePatientForm';
import axios from 'axios';
import DischargeSummaryOutput from '../DischargeSummary/dischargeSummaryOutputPopup';
import ClinicalNotesModal from '../ClinicalNote/ClinicalNoteOutput/clinicalNotePopup';

const { TabPane } = Tabs;




export default function MedicalDocumentation() {
  const [openPatientIntakeForm, setOpenPatientIntakeForm] = useState(false);

  const [openPatientClinicalForm, setOpenPatientClinicalForm] = useState(false);
  const [deleted,setDeleted]=useState(false);



 

  
  const [clinicalData,setClinicalData]=useState({})
  const [clinicalOpen,setClinicalOpen]=useState(false);
  const [clinicalViewOpen,setClinicalViewOpen]=useState(false);

  const [viewClinical,setViewClinical]=useState(false);

  const [activeTab, setActiveTab] = useState('1');
  const [clinicalNotesData, setClinicalNotesData] = useState([]);
  const [patientIntakeFormData, setPatientIntakeFormData] = useState([]);
  const [id,setId]=useState("")
 

  const [loading, setLoading] = useState(false);


  const handleClick = () => {
    if (activeTab === '1') {
      setOpenPatientClinicalForm(true);
      return;
    } else if (activeTab === '2') {
      setOpenPatientIntakeForm(true);
      return;
    }
  };

  const getTableData = () => {
    switch (activeTab) {
      case '1':
        return clinicalNotesData; // Empty, as there's no data yet for discharge summary
      case '2':
        return patientIntakeFormData;
     
      default:
        return [];
    }
  };

  const getTableColumns = () => {
    switch (activeTab) {
      case '1':
        return clinicalNotesColumns;
      case '2':
        return patientIntakeColumns;
    
      default:
        return [];
    }
  };

  const getButtonText = () => {
    switch (activeTab) {
      case '1':
        return 'Generate Clinical Notes';
      case '2':
        return 'New Patient Intake Form';
    
      default:
        return 'Generate New';
    }
  };

  // Fetch Clinical Notes and Patient Intake Data on Tab Change or Component Mount
  useEffect(() => {
    const token =localStorage.getItem("accessToken")
    if (!token) {

      message.error("You are not authorized! please login");
      return;
    }
    
      
      fetchClinicalNotesData();
      setLoading(true);
      fetchPatientIntakeData();
     
    
  }, [deleted,openPatientClinicalForm,openPatientIntakeForm]);

  const fetchClinicalNotesData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+'/get_clinical_notes/',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
          },
        }

      ); // replace with real API endpoint
      const data = await response.data;
      console.log(data)
      setClinicalNotesData(data);
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return
      }
      console.log(error)
     
    } finally {
      setLoading(false);
    }
  };
 
  const fetchPatientIntakeData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+'/get_patient_forms/',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
          },
        }
      ); // replace with real API endpoint
      const data = await response.data;
      console.log(data)
      setPatientIntakeFormData(data);
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return
      }
    } finally {
      setLoading(false);
    }
  };
 
  
  const clinicalNotesColumns = [
   
    {
      title: 'Patient Name',
      dataIndex: 'patient_name',
      key: 'patient_name',
    },
  
    {
      title: 'Date Generated',
      dataIndex: 'created_at',
      key: 'created_at',
    },
   
    {
      title: 'Status/Actions',
      key: 'actions',
      render: (row) => (
        <span className="flex space-x-4">
          <EyeOutlined  onClick={(e)=>handleEditDataClinicalView(row.id)} className="text-gray-400 cursor-pointer" />
          <EditOutlined  onClick={(e)=>handleEditDataClinical(row.id)}  className="text-gray-400 cursor-pointer" />
          <DeleteOutlined onClick={(e)=>handleDeleteClinical(row.id)} className="text-gray-400 cursor-pointer" />
          <SyncOutlined className="text-gray-400 cursor-pointer" />
        </span>
      ),
    },
  ];
  
  const patientIntakeColumns = [
   
    {
      title: 'Patient Name',
      dataIndex: 'patient_name',
      key: 'patient_name',
    },
    {
      title: 'Patient Email',
      dataIndex: 'patient_email',
      key: 'patient_email',
    },
    {
      title: 'Form Status',
      render: (row) => (
        <span className="flex space-x-2">
          <p>{row.form_status ? 'Submitted' : 'Not Submitted'}</p>
         
        </span>
      ),
      key: 'form_status',
    },
  
  
    {
      title: 'Date Generated',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: () => (
        <span className="flex space-x-2">
          <EyeOutlined className="text-gray-400 cursor-pointer" />
         
        </span>
      ),
    },
  ];



  const handleDeleteClinical = async (id) => {
    try {
      const response = await axios.delete(process.env.REACT_APP_BASE_URL+`/delete_clinical_note/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success('clinical note record deleted successfully');
        setDeleted(!deleted);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return
      }
      console.error('Error deleting record:', error);
    }
  };



  const handleEditDataClinical = async (id) => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+`/get_clinical_note/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        setClinicalData(data)
        setId(id)
        setClinicalOpen(true);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return
      }
      console.error('Error fetching summary:', error);
      return null;
    }
  };

  const handleEditDataClinicalView = async (id) => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+`/get_clinical_note/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        setClinicalData(data)
        setViewClinical(true);
        setClinicalViewOpen(true);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return
      }
      console.error('Error fetching summary:', error);
      return null;
    }
  };



  return (
    <div className="p-6 border-2 border-gray-100 mt-4 ">
      <h1 className="text-2xl font-bold mb-4 text-center">Generate Clinical Note</h1>
      <div className="flex max-md:flex-col items-center justify-between mb-4 ">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          
          className="max-xl-w-[40%]"
        >
          
          <TabPane tab="Clinical Notes" key="1" />
          <TabPane tab="Patient Intake Form" key="2" />
        </Tabs>
        <Button 
        type="primary"
        className="primary mb-4"
        onClick={handleClick}>
        {getButtonText()}
        </Button>
      </div>

      {loading ? (
        <div className='flex w-full justify-center items-center'>
        <Spin tip="Loading..." />
        </div>
      )  : (
        <Table
          columns={getTableColumns()}
          dataSource={getTableData()}
          pagination={false}
          scroll={{ x: 'max-content' }}
         className='border-l-[1px] border-r-[1px] border-gray-200 overflow-x-auto w-full'
        />
      )}

     
      {openPatientIntakeForm && <PatientIntakeForm openPatientIntakeForm={openPatientIntakeForm} setOpenPatientIntakeForm={setOpenPatientIntakeForm} />}
      {openPatientClinicalForm &&<ClinicalNotePatientForm isOpen={openPatientClinicalForm} setOpenPatientClinicalForm={setOpenPatientClinicalForm} />}

      {clinicalOpen &&<ClinicalNotesModal isOpen={clinicalOpen} onClose={setClinicalOpen} data={clinicalData}  id={id} />}
      {clinicalViewOpen &&<ClinicalNotesModal isOpen={clinicalViewOpen} onClose={setClinicalViewOpen} data={clinicalData} view={viewClinical}  />}

    </div>
  );
}
